.loginpagecontainer{ background: linear-gradient(90deg, #e3f6f6 58.3%, #fff 41.7%) }
.loginpagecontainer .row{min-height: 100vh; display: flex; justify-content: center; align-items: center;}
.text-center{text-align: center;}
.Loginbox{padding: 30px 50px;}
.Loginbox .logo{text-align: center; width: 140px; margin: 0 auto 30px;}
.Loginbox .logo img { width: 100%; }
.mb-3{margin-bottom: 1rem;}
.login-form-label{font-size: 16px; color: #333; font-weight: 400;}
.login-form-control{ box-shadow: none; outline: none; padding: 6px 30px !important; height: 40px;}
.login-form-control :focus{box-shadow: none; outline: none; border-color: #ccc;}
.login-form-control::placeholder{ color: #ccc; font-size: 14px;}
.inputlog{position: relative;}
.icon{position: absolute; top: 40px; left: 8px; width: 16px;}
.forgot_password { text-align: right; }
.forgot_password a{ color: #e6146e; font-size: 14px;}
.loginbtn{ background: #e6146e ; color: #fff ; border: none ; padding: 8px 30px ; font-size: 15px;}
.loginbtn:hover, .loginbtn:focus, .loginbtn:active{ background: #bd0e5a; border: none ; outline: none; box-shadow: none;}
