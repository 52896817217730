.dashboard-greeting{background: #F1F9F9; box-shadow: 0px 3px 6px #d3d3d3; padding: 20px; border-radius: 10px; margin-bottom: 15px;justify-content: space-between; align-items: center;}
.dashboard-greeting .welcome-head { font-size: 18px; }
.dashboard-greeting.row{margin-left: 0; margin-right: 0;}
.home-red-color{color: #E0166C}
.welcom-latest-news{ padding: 12px;margin-top: 8px;border: 1px solid rgba(204, 204, 204, 0.7);border-radius: 4px;max-height: 175px; overflow-y: auto; }
.welcom-latest-news p{font-size: 16px; line-height: 24px; margin: 0;}
.welcom-latest-news .list-group{border: none;}
.dashboard-greeting .welcom-img{width: 300px; height: 200px; float: right;}
.welcom-latest-news .list-group-item{border:none; background: #f1f9f9; font-size: 14px; line-height: 20px; padding-left: 0;}
.welcom-latest-news::-webkit-scrollbar{width: 3px; height: 10px; border-radius: 10px;}
.welcom-latest-news::-webkit-scrollbar-track{background: #f5f5f5;border-radius: 10px;}
.welcom-latest-news::-webkit-scrollbar-thumb{background: #E0166C;}

.recent-tasks-section{ padding: 20px; box-shadow: 0px 3px 6px #d3d3d3; border-radius: 10px; margin-bottom: 15px;}
.recent-tasks-section .tasks-section-head{display: flex; justify-content: space-between; border-bottom: 1px solid #e7e7e7; margin-bottom: 16px; padding-bottom: 10px;}
.recent-tasks-section .tasks-section-head h2{ font-size: 18px;}
.recent-tasks-section .tasks-section-head a{color: #757575;}

.dashboard-client-sections { padding: 20px; box-shadow: 0px 3px 6px #d3d3d3; border-radius: 10px; min-height: 320px; }
.dashboard-client-sections .clients-section-head{display: flex; justify-content: space-between; border-bottom: 1px solid #e7e7e7; margin-bottom: 16px; padding-bottom: 10px;}
.dashboard-client-sections .clients-section-head h2{ font-size: 18px;}
.dashboard-emp-perform{padding: 20px; box-shadow: 0px 3px 6px #d3d3d3; border-radius: 10px; min-height: 320px; vertical-align: middle;}