@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700;900&display=swap');
*{font-family: 'Roboto', sans-serif !important;}

.navBarText {
  margin-left: 10px !important;
  font-size: 17px;
  margin-top: 13px;
}
.show{opacity: 1;}
b, strong { font-weight: 500; }

.button:focus{box-shadow: none;}
.form-control{box-shadow: none !important;}
.unit-test-check .form-check{ display: inline-block; font-size: 12px;}
.unit-test-check .form-check label{font-weight: 400;}

::-webkit-scrollbar { width: 5px; height: 50px;}
::-webkit-scrollbar-track { box-shadow: inset 0 0 5px rgb(255, 255, 255);  border-radius: 4px; }
::-webkit-scrollbar-thumb { background: #ccc;  border-radius: 4px; height: 50px;}
::-webkit-scrollbar-thumb:hover {  background: #ccc; }

.error{font-size: 12px; line-height: 20px; color: red; margin-bottom: 0;}

.chat-list {
  height: 74vh;
  background-color: white;
}
.message-list {
  height: 68vh;
  border-left: 2px solid #f4f4f4;
  border-right: 2px solid #f4f4f4;
}
.user-list {
  height: 300px;
  overflow-y: scroll;
}
.message {
  margin-top: 10px;
}
.rce-mbox-text {
  padding: 3px;
}
.rce-mbox {
  background-color: antiquewhite !important;
}
.rce-mbox-right-notch {
  fill: aliceblue !important;
}
.rce-mbox-left-notch {
  fill: antiquewhite !important;
}
.rce-mbox-right {
  background-color: aliceblue !important;
}
.form-group{
  border: 2px solid #f4f4f4;
}

.header--navbar{
  margin-bottom: 0px;
  background-color: #fff;  
}
.sm--icon {font-size: 10px;}
.fa--icon{width: 30px; height: 30px; color: #4f4f4f; padding: 8px; border-radius: 3px; cursor: pointer; border: 1px solid #ececec;}
.fa--icon:hover .sm--icon{color: #fff;}

.view--icon:hover{background: #25bcb7;}
.edit--icon:hover{background: #33afd5;}
.bug--icon:hover{ background: orange;}
.add--icon:hover{background: #2c77b8; }
.delete--icon:hover {background: rgb(195, 6, 6);}

.cautious--icon{ color: red}

/* --icon button--- */
.fa--icon1{padding: 5px;  border-radius: 3px; cursor: pointer}
.add--icon1{background: #3892e3; color: #fff}
.add--icon1:hover{background: #2c77b8; }
.edit--icon1 { background: #33afd5; color: #fff}
.edit--icon1:hover { background: #298ead;}
.delete--icon1{background: #ed3447; color: #fff}
.delete--icon1:hover {background: rgb(195, 6, 6);}
.close--icon1{border: 1px solid #333}
.close--icon1:hover{color: #fff;
  background-color: #212529;
  border-color: #212529;}

span.project--status-Running, span.task_status--In.Progress {line-height: 16px;text-align: center; display: inline-block; min-width:80px; color: #007bff; border: 1px solid #007bff; padding: 3px 8px; border-radius: 3px; font-size: 12px; }
/* span.project--status-Not.Started { background: #eea944; color: #fff; padding: 3px 8px; border-radius: 3px; font-size: 10px; } */
span.task_status--Completed, .project--status-Completed{line-height: 16px;text-align: center; display: inline-block; min-width:80px; color: #28a745; border: 1px solid #28a745; padding: 3px 8px; border-radius: 3px; font-size: 12px; }
span.task_status--Not.Started, .project--status-Not.Started{line-height: 16px;text-align: center; display: inline-block; min-width:80px;color: #f4694c; border: 1px solid #f4694c; padding: 3px 8px; border-radius: 3px; font-size: 12px; }
span.priority--high,span.priority--High{text-align: center; display: inline-block; min-width:50px; background: #f44336; color: #fff; padding: 3px 8px; border-radius: 3px; font-size: 10px; text-transform: capitalize;}
span.priority--low{text-align: center; display: inline-block; min-width:50px; background: #ffc107; color: #fff; padding: 3px 8px; border-radius: 3px; font-size: 10px; text-transform: capitalize;}
span.priority--medium{text-align: center; display: inline-block; min-width:50px; background: #41b2b8; color: #fff; padding: 3px 8px; border-radius: 3px; font-size: 10px; text-transform: capitalize;}
.project--status-Analysis{line-height: 16px;text-align: center; display: inline-block; min-width:80px; color: #e48f2a; border: 1px solid #e48f2a; padding: 3px 8px; border-radius: 3px; font-size: 12px;}

.sidebar--light{position: sticky; top: 52px; display: flex; flex-direction: column; justify-content: space-between; overflow-y: auto; height: 90vh;}
.sidebar--light .siderbar--footer p{text-align: center; font-size: 12px; border-top: 1px solid #ccc; padding: 10px 16px; margin: 0;}
.navbar-default .navbar-brand{color: #333;}
.sidebar--light::-webkit-scrollbar { width: 3px; height: 50px;}
.sidebar--light::-webkit-scrollbar-track { box-shadow: inset 0 0 5px #fff;  border-radius: 4px; }
.sidebar--light::-webkit-scrollbar-thumb { background: #ccc;  border-radius: 4px; height: 50px;}
.main--panel.row { margin-top: 52px; background: #fbfbfb;}

iframe{display: none !important;}

.card--fluid {padding: 20px;  border-radius: 10px; border: 1px solid #f1f1f1;
  /* box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px rgb(0 0 0 / 14%), 0px 1px 3px rgb(0 0 0 / 12%); */
}
.card-fluid-header{ display: flex; justify-content: space-between; align-items: center; padding-bottom: 15px; margin-bottom: 30px; border-bottom: 1px solid #f1f1f1;}
.card-fluid-right{ display: flex; justify-content: space-between; align-items: center; }
.card-fluid-header p{font-size: 20px; font-weight: 500; color: #000; margin: 0;}
.form-label{ font-size: 14px; font-weight: normal; color: #333;}
.form-control, .form-select{font-size: 1.3rem;}

/* Modal css */
.modal-header, .modal-body{padding:10px 20px;}
.modal-title { font-size: 18px; }
.modal-footer{justify-content: flex-start; padding: 10px 20px; align-items: baseline;}
.modal-header .btn-close { font-size: 14px; }

.confirm--modal .delete--message{font-size: 16px;}
.confirm--modal .modal-footer{justify-content: space-around;}
.total--time {text-align: right;font-size: 18px;margin-right: 30px;}
.total--time strong {margin-right: 17px;}
/* Header Component */
.main-header{border: 0 none; border-bottom: 1px solid #f3f3f3; padding: 0; position: fixed; width: 100%; top: 0px; left: 0; z-index: 99; background: #fff; border-radius: 0;}
.main-header .nav_logo{font-size: 30px; display: flex; align-items: center; margin-left: -9px !important; font-weight: 500; letter-spacing: 2px;}
.main-header .avatar_img{ width: 40px; }
.main-header a{font-size: 16px; line-height: 24px; text-decoration: none;}
.notifications_icon a {font-size: 10px;}
.navbar-text{padding: 0; margin: 0; display: flex; align-items: center;}
.Logged_user{ background: #fff; border: none; color: #333; font-size: 16px; }
.Logged_user:hover,.Logged_user:focus,.Logged_user:active{background: #fff; border: none; color: #333; box-shadow: none;}
.btn-primary.active, .btn-primary:active, .show>.btn-primary.dropdown-toggle {
    color: #333;
    background-color: #fff;
    border: none;
    box-shadow: none !important;
    outline: 0;
}
.dropdown-menu[data-bs-popper] { top: 100%; right: 0!important; left: initial; margin-top: 10px; min-width: 12rem; border-radius: 4px;}
.dropdown-menu[data-bs-popper] a{ font-size: 14px; padding: 5px 20px;}
.notifications_icon{position: relative; color: #333; margin-right: 25px;}
.notifications_icon .redbell{width: 16px; height: 16px; display: flex; font-size: 12px; justify-content: center; align-items: center; color:#fff; background: #e0166c; border-radius: 50%; position: absolute; top: -6px; right: -7px;}

/* Siderbar Component */
.sidebar--panel{border-right: 1px solid #f3f3f3; padding: 0; position: relative; background: #fff;}
.sidebar--panel .sidebar--menu{padding: 0; }
.sidebar--panel .sidebar--menu li a{
  padding: 15px 16px;  
  display: flex;
  align-items: center;
  text-decoration: none;
  font-size: 16px;
  font-weight: 400;
  line-height: 16px;
  color: #333;
}
.sidebar--panel .sidebar--menu li:hover{background: #25bcb7; color: #fff;}
.sidebar--panel .sidebar--menu li:hover a{color: #fff;}
.sidebar-project-canvas {max-width: 226px; margin-top: 52px;border-right: none;}

.sidebar-project-menu {
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: -1px;
  background: #fff;
  width: 0px;
  }
  .sidebar-project-menu.open {
    width: 100%;
  }

/* ---------Dashboard css start------------ */
/* DashboardProjectsView Component */
p{margin-bottom: 8px;}
.recnt-project-box{ padding: 20px;  border-radius: 10px; margin-bottom: 15px; box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px rgb(0 0 0 / 14%), 0px 1px 3px rgb(0 0 0 / 12%);}
.recnt-project-box .proj-head{display: flex; justify-content: space-between; align-items: center;}
.recnt-project-box .proj-head h2{font-size: 18px;}
.recnt-project-box .proj-head a{color: #757575}
.recnt-project-box .project-category{font-size: 15px;}
.badge{font-size: 12px; padding: 4px 12px;}
.recnt-project-box .proj-details{display: flex; justify-content: space-between; align-items: center; margin: 10px 0; padding: 10px 0; border-bottom: 1px solid #e7e7e7; border-top: 1px solid #e7e7e7;}
.recnt-project-box .proj-details .val{ font-size: 18px; line-height: 24px; font-weight: 500; text-align: center; margin: 0;}
.recnt-project-box .proj-details .lbl{font-size: 15px;  color: #757575;}
.proj-update-history{display: flex;  justify-content: space-between; align-items: center;}
.proj-update-history .update_lbl{font-size: 15px; color: #757575; line-height: 22px;}
.proj-update-history .update_time{ font-size: 15px; }
.add-btn{
  background-color: #e0166c;
  height: 40px;
  font-size: 15px;
  display: inline-block;
  line-height: 40px;
  border-radius: 3px;
  border: none;
  color: #fff;
  text-decoration: none;
  padding: 0px 20px;
}
.close-btn{ height: 40px; font-size: 15px; display: inline-block; line-height: 40px; border-radius: 3px; border: 1px solid #333; color: #333;  padding: 0px 20px;}
.add-btn:hover,.add-btn:focus,.add-btn:active{background-color: #b90b56; color: #fff; text-decoration: none; box-shadow: none;}

/* DashboardTasksView Component */
.recnt-task-box{box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px rgb(0 0 0 / 14%), 0px 1px 3px rgb(0 0 0 / 12%);
  padding: 16px;  border-radius: 10px; min-height: 275px; }
.recnt-task-box .task-head{display: flex; justify-content: space-between; align-items: baseline; border-bottom: 1px solid #e7e7e7;}
.recnt-task-box .task-head h6{font-size: 14px;}
.recnt-task-box .task-head a{color: #757575}
.recnt-task-box .task-name-{font-size: 14px; font-weight: 500; line-height: 20px; min-height: 70px; margin-top: 8px; padding-bottom: 8px; border-bottom: 1px solid #e7e7e7}
.recnt-task-box .client-msg{font-size: 12px; position: relative;}
.recnt-task-box .client-msg:hover .dashtooltip{display: block;}
.recnt-task-box .task-details .task_duration .lbel{font-size: 12px; color: #757575; line-height: 20px; margin-bottom: 8px; }
.add-task-btn{border: none;
  padding: 5px 20px;
  background: #fff;
  color: #333;
  font-size: 12px;
  font-weight: 500;
  width: 100%;
  margin-top: 8px;
  border-radius: 20px;
  box-shadow: 1px 2px 4px rgb(0 0 0 /16%);
}
.dashtooltip{
  position: absolute;
  display: inline-block;
  top: 20px;
  left: 0;
  overflow: hidden;
  background: rgba(0, 0, 0, 0.7);
  padding: 10px;
  color: #fff;
  font-size: 12px;
  border-radius: 4px;
  white-space: wrap;
  height: auto;
  z-index: 99;
  transform: translate(0px, 0);
  max-width: 200px;
}
/* DashboardClientUpdate Component */
.recnt-client-box{box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px rgb(0 0 0 / 14%), 0px 1px 3px rgb(0 0 0 / 12%);
  padding: 16px;  border-radius: 10px; min-height: 210px;}
.recnt-client-box .client-name-{font-size: 14px; font-weight: 500;}
.recnt-client-box .client-company{font-size: 11px; color: #757575;}
.recnt-client-box .client-msg{font-size: 14px;  margin: 8px 0;}
.recnt-client-box .client-msg-time{font-size: 12px; color: #757575;}

/* DashboardEmpperformance component */
.emp-performance-box{padding: 15px; margin-bottom: 10px; box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px rgb(0 0 0 / 14%), 0px 1px 3px rgb(0 0 0 / 12%);
  display: flex; justify-content: space-between; align-items: center; border-radius: 10px;}
.emp-performance-box .date--{background: #fff; border-radius: 50%; display: flex; flex-wrap: wrap; 
  width: 50px; height: 50px; box-shadow: 1px 3px 6px rgb(0 0 0 /10%); justify-content: center; align-items: center; text-align: center;}
.emp-performance-box .date-- p{font-size: 16px; line-height: 19px; margin: 0; font-weight: 500; color: #333;}
.emp-performance-box .date-- p span{color: #757575; font-size: 14px;}
.emp-performance-box .working-hour .working-lbl{font-size: 14px; line-height: 24px; color: #757575; font-weight: 500; margin: 0;}
.emp-performance-box .working-hour .working-val{font-size: 14px; color: #333; font-weight: 500; text-align: center;}
.emp-performance-box .productivity-- p{font-size: 14px; line-height: 24px; color: #757575; font-weight: 500; margin: 0;}
.emp-performance-box .progress{ height: 8px; margin-bottom: 17px; }

/* myTask */
.task-cards{padding: 20px; border-radius: 10px; margin-bottom: 15px; border: 1px solid #ccc;}
.task-cards .proj-head h2{font-size: 18px;}
.task-cards .proj-details .task--Description .descp-text{ font-size: 14px; line-height: 20px;}
.task-cards .form-select{width: 40%; margin-right: 1px;}
.task-cards .proj-update-history .update_lbl { margin: 0; }
.task-cards .proj-update-history .update_time{ font-size: 14px;}

/* ---------Dashboard css ends------------ */

/* ---------Projects css starts------------ */
.project-list-head{ display: flex; justify-content: space-between; align-items: center; padding-bottom: 8px; padding-bottom: 15px; margin-bottom: 30px;border-bottom: 1px solid #f1f1f1;}
.project-list-head h2{font-size: 20px;}
.project-head-right{display: flex; align-items: center;}
.search--form{position: relative; margin-right: 20px;}
.search--icon{position: absolute; top: 14px; left: 8px; color: #ccc; }
.search--form .form-control{box-shadow: none; outline: none; padding: 6px 30px !important; height: 40px; font-size: 14px;}
.search--form .form-control::placeholder{color: #ccc}
.bucket-description{width: 400px;}

/* ---------Projects Dashbaard css starts------------ */
.project-dashboard-box { border-radius: 4px; margin-bottom: 15px;min-height: 290px; box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px rgb(0 0 0 / 14%), 0px 1px 3px rgb(0 0 0 / 12%);
  background: #fff;}
.proj-dashboard-head{ border-bottom: 1px solid #ccc; padding: 16px;}
.proj-dashboard-head h2{ font-size: 16px; color: #000; margin: 0; }
.proj-dashboard-details{ padding: 10px 16px;}
.proj-dashboard-details .detailRow{ display: flex; justify-content: space-between; align-items: center;}
.proj-dashboard-details .detailRow .lbl{ font-size: 14px; font-weight: 500;}
.proj-dashboard-details .detailRow .val{ font-size: 14px; font-weight: 400;}

  /* -people- */
.people--box { padding: 16px; box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px rgb(0 0 0 / 14%), 0px 1px 3px rgb(0 0 0 / 12%);
  border: 10px; background: #fff;}
.people_img{ border-bottom: 1px solid rgba(204, 204, 204, 0.7); min-height: 60px;}
.people_img img{float: left; margin-right: 20px;}
.people--box p{margin: 0;}
.people--box .name_emp{font-size: 16px; font-weight: 500; color: #000;}
.people--box .position_emp{font-size: 14px; color: rgba(0, 0, 0, 0.54);}
.people--box .emp-details .emp_mail, .people--box .emp-details .emp_phone{display: flex; justify-content: flex-start;}
.people--box .emp-details .lbl{margin-right: 8px; font-size: 16px; color: rgba(0, 0, 0, 0.54); }
.people--box .emp-details .val{ font-size: 16px; color: #333; }
.addMemberIconBox{align-self: center; margin-top: 12px;}

  /* -Task- */
  .dashboard-task-section .task--main{padding: 16px; border-radius: 10px; border: 1px solid #ccc; background: #fff;}
  .dashboard-task-section .task--main .footerpagination{display: none;}
  .dashboard-task-section .card{border: none} 
  .dashboard-task-section .task-bar-head{border-bottom: 1px solid #333;background: #fff; display: flex;justify-content: space-between; padding-right: 0; padding-left: 0;}
  .task-bar-head .sprint-duration { color: #757575; font-size: 12px; }
  .tasks-inner-data .card { border: none; }
  .add-sm-btn{color: #ffffff; background-color: #e0166c; cursor: pointer; width: 24px; height: 24px; align-items: center; display: flex; justify-content: center; border-radius: 50%; padding: 0;}
  li.list-group-item { border: none; padding: 5px 0; margin-bottom: 1px; font-size: 14px; line-height: 24px; border-bottom: 1px solid #ccc; display: flex; justify-content: space-between; align-items: center;}
  ol.list-group{margin-top: 10px;}

  .dashboard-task-section .task--main button.accordion-button { display: flex; justify-content: space-between; align-items: center; padding-left: 0; padding-right: 0;}
  .dashboard-task-section .task--main button.accordion-button h3{margin: 0; font-size: 16px;}
  .dashboard-task-section .task--main .accordion-button:not(.collapsed){ color: #e0166c; box-shadow: none; background: transparent;}
  .dashboard-task-section .task--main .accordion-button.collapsed{box-shadow: none;}
  .dashboard-task-section .task--main .accordion-body{ padding: 0 ;}
  .dashboard-task-section .task--main .accordion-item{border: none; }
  .dashboard-task-section .task--main .task-name span{color: #757575}
  .dashboard-task-section .task--main .accordion-button::after{content: none}
  .no-data-found img{ width:400px; height: 300px;}
  .table-task-id{font-weight: 500;}

  /* ---task canvas--- */
  .task--offcanvas { width: 650px; }
  .task--offcanvas .offcanvas-header { border-bottom: 1px solid #ccc; padding: 10px 16px;}
  .task--offcanvas .offcanvas-body{padding: 0; position: relative;}
  .task--offcanvas .task-action{display: flex;}
  .task--offcanvas h4{font-size: 16px; margin: 0;}
  .task--offcanvas button.btn-close { position: absolute; right: 10px; opacity: 0; width: 48px; height: 29px; }
  .task--offcanvas .form-control{ border: none; padding: 0;}
  .task--offcanvas .form-select{ font-size: 1.1rem; height: 27px;}
  .task--offcanvas .form-label{ font-size: 16px; font-weight: 500; padding: 0;}
  .task--offcanvas .top_body{ border-bottom: 1px solid #ccc; padding: 10px 16px;}
  .task--offcanvas .top_body .other-details{display: flex; justify-content: space-between; align-items: center; border-bottom: 1px solid #ccc;}
  .task--offcanvas .top_body .other-details p{font-size: 12px; font-weight: 500;}
  .task--offcanvas .top_body .other-details p span{font-weight: 400;}
  .task--offcanvas .task--name{font-size: 16px; margin: 0; font-weight: 500;}
  .task--offcanvas .task--message--box{padding: 16px 0; overflow: hidden; overflow-y: auto; max-height: 300px;}
  .task--offcanvas .task--message--box .taskmessage{display: flex; margin-bottom: 10px; padding: 10px 16px;}
  .task--offcanvas .task--message--box .taskmessage .avtar-img img{ width: 40px; height: 40px; border-radius: 50px; border: 1px solid #ccc; margin-right: 16px;}
  .task--offcanvas .task--message--box .taskmessage .avtar-name{font-size: 16px; font-weight:500; margin-bottom: 0;}
  .task--offcanvas .task--message--box .taskmessage .avtar-name span{font-size: 10px; font-weight:400; color: rgba(0, 0, 0, 0.54); margin-left: 10px;}
  .task--offcanvas .task--message--box .taskmessage .avtar-text{font-size: 15px;}
  .task--offcanvas .taskmessage.out { background: #F1F9F9; border-radius: 4px;}
  .task--offcanvas .send--message--panel{border-top: 1px solid #ccc; padding: 10px; align-items: center;position: fixed; width: 650px; bottom: 0; background: #fff;}
  .task--offcanvas .send--message--panel .text-box{position: relative; margin-left: 10px;}
  .task--offcanvas .send--message--panel .form-control{border: 1px solid #ccc; padding: 10px 40px; min-height: 40px;}
  .task--offcanvas .sendmsgicon{position: absolute; top: 0; right: 0; background: #26bdb8; width: 40px; cursor: pointer; height: 40px; padding: 12px; border-radius: 0 4px 4px 0;}
  .task--offcanvas .sendmsgicon svg{color: #fff;}
  .task--offcanvas .attachicon{position: absolute; left: 5px; top: 5px; color: rgba(0,0,0, 0.34); padding: 5px; cursor: pointer;}
  .task--offcanvas .files--list{border-top: 1px solid #ccc; padding-top: 10px;  }
  .task--offcanvas .task-uploaded-files{display: flex; flex-wrap: wrap; padding-left: 0;}
  .task--offcanvas .task-uploaded-files li{font-size: 14px; margin-right: 30px; list-style-type: none;}
  .task--offcanvas .form-control:disabled, .form-control[readonly] { background-color: #fff; }
  

  /* -Message- */
  .message-overview .message-summary-box{padding: 16px; background: #f7f7f7; margin-bottom: 20px; border: 1px solid #ccc; border-radius: 4px;}
  .message-overview .message-summary-box h4{ font-size: 16px;}
  .message-overview .message-summary-box .summary-data{display: flex; justify-content: space-between; align-items: center;
    background: #fff;
    padding: 10px;
    border-radius: 4px;
    box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px rgb(0 0 0 / 14%), 0px 1px 3px rgb(0 0 0 / 12%);
  }
  .message-overview .summary-data .message-text{font-size: 14px; width: 30%; display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;}
  .message-overview .summary-data p{font-size: 14px; line-height: 20px; margin: 0;}
  .message-overview .summary-data .messagelabel{color:#757575}
  .reply-btn{
    background-color: #26bdb8;
    height: 30px;
    font-size: 14px;
    display: inline-block;
    line-height: 30px;
    border-radius: 3px;
    border: none;
    color: #fff;
    text-decoration: none;
    padding: 0px 10px;
  }
  .reply-btn:hover{background-color: #1f8a86; color: #fff; text-decoration: none;}

  .message--updates .last-updated-message{ border: 1px solid #ccc; display: flex; border-radius: 4px;}
  .message--updates .user-information{width:150px; padding: 16px; background: #f7f7f7;}
  .message--updates .user-information p, .message--updates .user-message-body p{font-size: 16px;}
  .message--updates .user-information .user-update-time{color: #757575;}
  .message--updates .user-message-body{padding: 16px; font-size: 16px;}
  .message--reply--box{border: 1px solid #ccc; border-radius: 4px; padding: 16px;}
  .message--reply--box h4{font-size: 16px;}
  .message--reply--box .title{font: 500;}

  .uploaded-images{margin-top: 10px; padding-left: 0;}
  .uploaded-images li{margin-bottom: 10px; list-style-type: none;}
  .uploaded-images li::marker { color: #25bcb7;}
  .uploaded-images li img{width: 25px; height: 25px; margin-right: 10px;}
  .uploaded-images li .close-icon{color: red; cursor: pointer;}
  .file--uploader{padding: 6px 12px; }
  .form-control:focus{outline: 0; border-color: #ccc; box-shadow: none;}

  /* Task bug tracker */
  .task-bug-action{display: flex; align-items: baseline;}
  /* .task-bug-action .bug-prior{font-size: 14px;} */
  .task-bug-action .bug-assignee{font-size: 14px;}
  .task-bug-action .form-select{width: auto}
  .task-bug-body .bug-info-label{font-size: 16px; font-weight:500;}
  .task-bug-body .bug-info-text{font-size: 15px;}
  .task-bug-body .bug-detailing{display: flex; align-items: flex-start;}
  .task-bug-body .bug-detailing .bug-label{font-size: 15px; font-weight:500; min-width: 130px;}
  .task-bug-body .bug-detailing .bug-value{font-size: 15px; margin-left: 40px; margin-bottom: 15px;}
  .task-bug-body .bug-detailing .bug-value1{border: 1px solid #ccc; border-radius: 4px; padding: 16px; margin-bottom: 15px; font-size: 15px; background: #fbfbfb; margin-left: 40px;}

  /* profile--offcanvas */
  .profile--offcanvas{width:650px}
  .offcanvas-header h4 { margin: 0; }
  .profile--offcanvas .offcanvas-header,.profile--offcanvas .task-bug-body{padding: 10px 20px;}
  .profile--offcanvas .bug-detailing .bug-label{min-width: 150px;}
  .profile--offcanvas .bug-detailing .bug-value, .profile--offcanvas .bug-detailing .bug-value1{margin-left: 0; min-width: 450px;}
  .profile--offcanvas .profile-info{display: flex; align-items: center; border-bottom: 1px solid #ccc; padding-bottom: 15px;}
  .profile--offcanvas .profile-info .profile--name{font-size: 16px; font-weight: 500;}
  .profile--offcanvas .profile-info .image-box{width: 80px;
    height: 80px;
    border: 1px solid #ccc;
    border-radius: 50%;
    font-size: 18px;
    display: flex;
    align-items: center;
    justify-content: center;   
  }
  .profile--offcanvas .task-action{display: flex;}
  .profile--offcanvas button.btn-close {position: absolute; right: 10px; opacity: 0; width: 48px; height: 29px;}

  /* pms--offcanvas */
 
  .offcanvas-header{border-bottom: 1px solid #ccc;}

  .notification--panel .notification-card{display: flex; justify-content: space-between; margin-bottom: 20px; align-items: center; border-radius: 4px; padding: 16px; box-shadow: 0px 0px 4px #e2e1e1}
  .notification--panel .notification-details{display: flex; align-items: flex-start;}
  .notification--panel .notification-details p{font-size: 14px; line-height: 22px; margin-left: 12px; width: 80%; margin-bottom: 0;}
  .notification--panel .day-inform{font-size: 14px;}
  .notification--panel .notification-time{font-size: 12px; opacity: 0.54; }
  .notification--panel img.avatar_img.rounded-circle {width: 40px; height: 40px; }

  .search--form.chat-search input {border: none;border-radius: 20px;}
  .search--form.chat-search { margin: 10px; }
  .Chat-panel-userlist{border-right: 1px solid #f3f3f3; padding-right: 0;}
  .rce-container-clist::-webkit-scrollbar { width: 5px; height: 50px;}
  .rce-container-clist::-webkit-scrollbar-track { box-shadow: inset 0 0 5px rgb(255, 255, 255);  border-radius: 4px; }
  .rce-container-clist::-webkit-scrollbar-thumb { background: #ccc;  border-radius: 4px; height: 50px;}

  .send--message--panel1{border-top: 1px solid #ccc; padding: 10px; align-items: center;position: relative; background: #fff;}
  .send--message--panel1 .text-box{position: relative; margin-left: 10px;}
  .send--message--panel1 .form-control{border: 1px solid #ccc; padding: 10px 40px; min-height: 40px;}
  .sendmsgicon{position: absolute; top: 0; right: 0; background: #26bdb8; width: 40px; cursor: pointer; height: 40px; border-radius: 0 4px 4px 0;}
  .sendmsgicon svg{color: #fff; margin-right: 10px;font-size: 16px;}
  .attachicon{position: absolute; left: 5px; top: 5px; color: rgba(0,0,0, 0.34); padding: 5px; cursor: pointer;}
  .chatt-option{background: #f4f4f4; border: none; color: #333; font-size: 16px;}
  .chatt-option:hover,  .chatt-option:focus{background: #f4f4f4; color: #333;}
  .chatt-dropdown a.dropdown-item { font-size: 14px; padding: 5px 20px; }
  .chatt-dropdown a.dropdown-item:hover { text-decoration: none; }
  .chatt-dropdown .dropdown-menu.show {right: 0!important;min-width: 12rem;border-radius: 4px;}

/* Suneditor css */
  .rdw-dropdown-selectedtext {color:#000; text-decoration: none; }
  .rdw-dropdown-selectedtext:hover{color:#000; text-decoration: none; }
  .rdw-editor-main{
    background: #fff;
    border: 1px solid #F1F1F1;
    border-top: none;
    min-height: 80px;
    padding: 0 10px;
  }
  .rdw-editor-toolbar{margin-bottom: 0 !important; background: #f7f7f7 !important}
  .rdw-option-wrapper{    background: #f7f7f7 !important}

@keyframes slide {
    from {
      background-position-x: 0;
    }
    to {
      background-position-x: 113px;
    }
  }

  .loader{
      display: none;
  }

  .bgloader {
    position: fixed;
    z-index: 9;
    left: 0;
    right: 0;
    bottom: 0px;
    top: 0;
    background: rgba(0,0,0,0.3);
  }
  .loaderShow {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    z-index: 99999999;
   
    height: 10px;
    border: 0;
    padding: 16px 0px;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    min-width: 300px;
  }
  .loaderShowbar {
    height: 30px;
    border-radius: 10px;
    width:100%;
   
    box-shadow: 0px 10px 13px -6px rgb(241, 249, 249);
    background-color: var(#25bcb7);
    background-image: repeating-linear-gradient(
      45deg,
      transparent,
      transparent 40px,
      #e0166c 40px,
      #e0166c 80px
    );

    animation: slide 4s linear infinite;
    will-change: background-position;
  }