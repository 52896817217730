button.actionCell {
    border: 0px;
    width: 30px;
    height: 30px;
    margin-right: 8px;
    background: #fbfbfb;
    display: inline-block;
}
button.actionCellDisabled {
    border: 0px;
    width: 30px;
    height: 30px;
    margin-right: 8px;
    background: #fbfbfb;
    display: inline-block;
    pointer-events: none;
}
button.actionCellDisabled .fa--icon{color: #7a7a7a;}
table td {border-color: #ececec;}
table tr:hover{ background-color: transparent !important;}
table tfoot tr:hover{background: transparent;}
.table>thead>tr>th, .table>tbody>tr>td {vertical-align: middle; border: none; }
.table>tbody>tr>td{ border-bottom: 1px solid #ececec; }
.tableHeader th:last-child,.tableHeader .headerCell{background: #26BDB8; color: #fff; font-size: 14px; border: none; font-weight: 500;}
tr.childInner .headerCell{font-size: 14px;}
.table>:not(:first-child){border: none;}
tfoot tr td { border: none; }
.usr-pagination.text-right { display: flex; justify-content: end; }
.usr-pagination span.pagination { background: #e0166c; padding: 5px 12px; color: #fff; cursor: pointer; border-radius: 3px; margin-left: 8px; opacity: 0.7;}
.usr-pagination span.pagination.current{opacity: 1;}
.anchor--link{ border-bottom: 1px solid #e0166c; cursor: pointer; transition: all 0.2s; font-weight: 500; position: relative;}
.table-hover>tbody>tr:hover>*{background: none; --bs-table-accent-bg: none}

.task--main .tableHeader .headerCell,
.task--main .tableHeader th:last-child{background: #e5e5e5; color: #000; font-weight: 500;}
